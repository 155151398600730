import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const bukavu = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Bukavu" />
    <h3 className='underline-title'>Bukavu</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        En ma caboche cachottière<br />
        Bukavu s'incarne en tableau expressionniste <br />
        La précieuse Joconde du Zaïre authentique <br />
        Sur la face  inégale duquel<br />
        La Nature-de-Vinci avec profusion peint<br />
        Ses motifs captivants: coqueluche des touristes<br />
        Son lac sournois m'insuffle immense bassin d'eau<br />
        Eau verte des mystères Bashi (1)<br />
        En ses montagnes tondues je rêve de colossales tombes<br />
        Tombes des mammouths du monde herculéen <br />
        S'érigeant en arènes de chlorophyle<br />
        Couronnant les eaux félonnes du lac<br />
        Clôture-prison aux évasions des nymphes lacustres <br />
        Immenses tombes piquées par endroits<br />
        D'eucalyptus drus de bananiers serrés...<br />
        Se mirant dans le lac reverti <br />
        Lorsque percée la montagne-couronne<br />
        Il y est né un fils faufilant en vallées:<br />
        RUZIZI-Manneken-Pis-Arrosoir<br />
        Irriguant KAMANIOLA grossissant TANGANYIKA<br />
        Intarissable ciel-liquide déverse sans règle<br />
        Ses averges gratuites sur cultures sans saison<br />
        Clémence climatique excite davantage<br />
        La passion amoureuse de ses amants<br />
        Voilà mon BUKAVU romanesque!<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Tribu SHI, Mushi, Bashi de Bukavu.</p>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default bukavu
